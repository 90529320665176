:root {
  /* --f7-theme-color: #4ac4f3; */
  --f7-theme-color: #3bb8e8;
  /* --f7-theme-color-rgb: 74, 196, 243; */
  --f7-theme-color-rgb: 55, 186, 236;
  /* --f7-theme-color-shade: #24b8f0; */
  --f7-theme-color-shade: #29aadc;
  /* --f7-theme-color-tint: #70d0f6; */
  --f7-theme-color-tint: #5acdf9;
  --f7-message-received-text-color: #3c3c3c;

  --f7-bars-text-color: var(--f7-theme-color);
}

@media (min-width: 768px) {
  // ion-app {
  //     max-width: 512px;
  //     margin: 0 auto;
  //     border-radius: 1rem;
  // }
}


/** Collaborator */
.page .page-header {
  display: flex;
}
.page .page-header .logo {
  margin-right: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.page .page-header .info {
  font-size: 1rem;
  flex: 1;
}
.page .page-header .info .summary {
  color: var(--f7-block-header-text-color);
  font-size: 0.8rem;
}
.page .page-header .info .name {
  font-weight: bold;
}
.page .page-header .info .tag {
  font-size: 0.7rem;
  color: var(--f7-block-header-text-color);
}

.page-banner {
  border-radius: 0.3rem;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}
.ios .page-banner {
  box-shadow: var(--f7-dialog-box-shadow);
}
/** End Collaborator */

.action-bar {
  color: var(--f7-block-strong-text-color);
  padding-top: var(--f7-block-padding-vertical);
  padding-bottom: var(--f7-block-padding-vertical);
  background-color: var(--f7-block-strong-bg-color);
  // border-top: 1px solid var(--f7-list-border-color);
  // border-bottom: 1px solid var(--f7-list-border-color);
  margin-top: 0;
}

.action-bar-item {
  text-align: center;
  color: #333;
  cursor: pointer;
}

.action-bar-item.link {
  display: initial;
}

.action-bar-item-label {
  margin-top: 5px;
  font-size: 11px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
}

.action-bar-item {
  .if-active {
    display: none;
  }
  &.action-bar-item-active {
    color: var(--f7-theme-color-shade) !important;
    .action-bar-icon {
      display: none;
    }
    .if-active {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .action-bar-item {
    margin-bottom: 1rem;
  }
}

/** Tabbar */
.tabbar i.icon,
.tabbar-labels i.icon {
}
.tabbar .tab-link-active,
.tabbar-labels .tab-link {
  .if-active {
    display: none;
  }
  .if-not-active {
    display: block;
  }
}
.tabbar .tab-link-active,
.tabbar-labels .tab-link-active {
  .if-active {
    display: block;
  }
  .if-not-active {
    display: none;
  }
}
/** End Tabbar*/


.page .profile-info .info {
  padding-top: 0;
}

.device-desktop body {
  max-width: 630px;
  margin: 0 auto;
}

.list .item-inner.no-line:after {
  display: none;
}