:root {
  /* --f7-theme-color: #4ac4f3; */
  --f7-scrollbar-background: #e4e9f2;
  --f7-scrollbar-thumb-background: #ff9800;
  --f7-theme-color: #3bb8e8;
  /* --f7-theme-color-rgb: 74, 196, 243; */
  --f7-theme-color-rgb: 55, 186, 236;
  /* --f7-theme-color-shade: #24b8f0; */
  --f7-theme-color-shade: #29aadc;
  /* --f7-theme-color-tint: #70d0f6; */
  --f7-theme-color-tint: #5acdf9;
  --f7-message-received-text-color: #3c3c3c;
}

@media (min-width: 768px) {
  // ion-app {
  //     max-width: 512px;
  //     margin: 0 auto;
  //     border-radius: 1rem;
  // }
}

// .disable-text-select {
//   -webkit-user-select: none;
// }

body {
  --ion-color-primary: #4ac4f3 !important;
}

.ios {
  --f7-notification-bg-color: rgba(250, 250, 250);
  @media (min-width: 786px) {
    --f7-dialog-width: 350px;
  }
}
.theme-dark {
  --f7-scrollbar-background: #101426;
}
.ios .theme-dark,
.ios.theme-dark {
  --f7-notification-bg-color: rgba(30, 30, 30);
}


.md {
  --f7-page-transition-duration: 250ms;
  --f7-page-bg-color: #efeff4;
}

.text-color-default {
  color: #3bb8e8 !important;
}

.color-default {
  color: #3bb8e8 !important;
}

.bg-color-default {
  background-color: #3bb8e8 !important;
}

.navbar .title {
  white-space: normal;
  // height: 30px;
  vertical-align: sub;
}

.navbar .title .textFitted {
  vertical-align: sub;
}

// .action-bar {
// }

.action-bar-item {
  text-align: center;
  color: #333;
  cursor: pointer;
}

.action-bar-item.link {
  display: initial;
}

.action-bar-item-label {
  margin-top: 5px;
  font-size: 11px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 768px) {
  .action-bar-item {
    margin-bottom: 1rem;
  }
}

.clear-after::after {
  content: "" !important;
  background-color: initial !important;
}

.link {
  user-select: initial;
  -webkit-user-select: initial;
}

/* iOS Cordova Tweak */

.device-cordova.device-ios {
  height: 100vh;
}

/* Your app custom styles here */

.ios {
  // --f7-actions-bg-color: #fff;
  --f7-actions-button-height: 45px;
  --f7-actions-button-font-size: 16px;
  --f7-actions-button-text-align: left;
}

.android {
  --f7-actions-bg-color: #fff;
  --f7-actions-button-height: 40px;
  --f7-actions-button-font-size: 16px;
  --f7-actions-button-text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

.actions-button {
  flex-direction: row-reverse;
}

.actions-button-media {
  margin-left: 16px;
}

.ios .actions-button-media {
  margin-right: 16px;
}

.actions-button-text {
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
}

/* .actions-button-text {
  padding-left: 16px;
  padding-right: 16px;
} */

/** Tabbar */
// .tabbar i.icon,
// .tabbar-labels i.icon {
// }
.tabbar .tab-link-active,
.tabbar-labels .tab-link {
  .if-active {
    display: none;
  }
  .if-not-active {
    display: block;
  }
}
.tabbar .tab-link-active,
.tabbar-labels .tab-link-active {
  .if-active {
    display: block;
  }
  .if-not-active {
    display: none;
  }
}
/** End Tabbar*/

.block.fill {
  padding: 0;
  overflow: hidden;
}

.actions-button.color-gray .actions-button-text {
  color: var(--ion-card-background);
}

.actions-label {
  justify-content: left;
  padding-left: 16px;
}

// .ios-translucent-modals .actions-button,
// .ios-translucent-modals .actions-label {
//   // background-color: rgba(var(--f7-actions-bg-color-rgb), 1);
// }

.actions-button.color-gray {
  color: var(--f7-menu-item-pressed-bg-color);
}

.actions-button-media i.icon {
  color: var(--swiper-theme-color);
}

.actions-button.color-red .actions-button-media i.icon {
  color: var(--f7-swipeout-delete-button-bg-color);
}

.list.inset ul {
  overflow: hidden;
}

.list-item-img {
  border-radius: 50%;
  background-color: #ddd;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  text-align: center !important;
  line-height: 30px;
  font-size: 44px;
  color: var(--ion-color-primary, #3880ff);
  font-weight: bold;
}

.list-item-img i {
  font-size: 29px !important;
  color: var(--ion-color-primary, #3880ff);
  font-weight: bold;
}

.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-avatar-container {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #ccc;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-card .demo-facebook-date {
  margin-left: 44px;
  font-size: 13px;
  color: #8e8e93;
}

.demo-facebook-card .card-footer {
  background: #fafafa;
}

.theme-dark .demo-facebook-card .card-footer {
  background: var(--f7-card-bg-color);
}

.demo-facebook-card .card-footer a {
  color: #81848b;
  font-weight: 500;
}

.demo-facebook-card .card-content img {
  display: block;
}

.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}

.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}

.profile-info {
  margin-top: 20px;
}

.profile-logo {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
}

.profile-title {
  text-align: center;
  font-weight: bold;
}

.profile-subtitle {
  text-align: center;
}

// .profile-form li .item-media i {
//   /* font-size: 44px;*/
// }

/** Dialog */
.dialog:not(.dialog-wrap) {
  // top: 200px;
}
.dialog.dialog-large {
  left: 39%;
  width: 353px;
  .dialog-title {
    font-size: 1.5rem;
  }
  .dialog-text {
    font-size: 1.5rem;
  }
  .dialog-button {
    font-size: 1.5rem;
  }
}
.ios .dialog-inner {
  padding-bottom: 0;
}
.dialog.dialog-inside {
  display: block;
  position: relative;
  opacity: 1;
  transform: translate3d(0, 0%, 0) scale(1);
  top: initial;
}

.dialog.dialog-wrap {
  height: 100%;
  border-radius: 0;
  width: 100%;
  left: 0;
  margin: 0;
  overflow-y: auto;
  background: transparent;
}

.dialog-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.dialog-buttons-vertical .dialog-buttons.has-icon {
  .dialog-button {
    padding-left: 1rem;
    text-align: left;
    i {
      float: right;
      font-size: 1.5rem;
      margin-top: 9px;
      margin-right: 0.4rem;
    }
  }
}

.dialog-inner {
  max-height: 80vh !important;
  overflow-y: auto !important;
}
/** End Dialog */

/* Buffer area */

#buffer-area {
  height: 0;
  overflow: auto;
  margin: initial;
  // transition: 300ms;
  // background-color: #fff;
  background-color: var(--f7-page-bg-color);
  ul {
    background-color: var(--ion-color-warning-tint);
  }
  .message-quote {
    // border-radius: initial;
    // border: initial;
    // margin: 0.5rem;
    // border-radius: 1.1rem;
    // background-color: #3bb8e8;
    // color: #fff !important;
    // .message-quote-content {
    //   // color: #fff;
    // }
    // .message-quote-from-name {
    //   // color: #fff !important;
    // }
    // .message-quote-time {
    //   // color: #fff !important;
    // }
    .actionButton {
      float: right;
      height: 32px;
      margin: 3px;
    }
    .message-quote-attachments {
      min-height: 35px;
    }
  }
  // a {
  //   // color: #fff;
  // }
}

.theme-dark {
  #buffer-area {
    ul {
      background-color: var(--f7-color-orange-tint);
    }
  }
}

.ios {
  #buffer-area.visabled {
    margin-top: var(--f7-safe-area-top);
  }
}

#buffer-area.visabled {
  height: 4rem;
}

// .views {
//   // transition: 300ms;
// }

#buffer-area.visabled + .views {
  height: calc(
    100% - 4rem - var(--f7-safe-area-top) - var(--f7-appbar-app-offset, 0px)
  );
}

/* End Buffer area */

/** Messages*/

.navbar-chat-room {
  .title {
    white-space: normal;
    line-height: 1.4em;
    display: flex;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
  }
}

// .messages-wrap {
//   /* height: 100%; */
//   /* position: relative; */
// }

.messages {
  min-height: initial;
  /* position: absolute; */
  /* bottom: 50px; */
  width: 100%;
}

.messages-visable {
  padding-bottom: 56px;
}

.messages-ghost {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.messages-ghost .message-bubble {
  max-width: 300px;
}

.messages-ghost.hidden {
  max-height: 0;
  opacity: 0;
}

.message {
  border-radius: 1rem;
  // .message-text {
  //   // line-height: 22px;
  // }
  // .message-content a {
  // }
  // &.message-sent .message-text a {
  // }
  .message-content a.mention {
    background: rgb(0 0 0 / 18%);
    border-radius: 6px;
    color: inherit;
    font-weight: bold;
    font-size: 14px;
    // white-space: nowrap;
    padding-left: 3px;
    padding-right: 3px;
    -webkit-user-select: initial;
    user-select: initial;
  }
  &:active {
    opacity: 0.6;
    transition-delay: 150ms;
    transition-duration: 300ms;
  }
  &.dim {
    opacity: 0.6;
    transition-delay: 150ms;
    transition-duration: 300ms;
    background-color: #ffce1b;
  }
  .message-quote {
    border-radius: 11px;
    background-color: #84848469;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-style: italic;
    .message-quote-image {
      .message-bubble-img-wrap {
        float: left;
        width: 32px;
        height: 32px;
        border-radius: 20%;
        margin: 2px;
        background-position: center;
        background-size: cover;
      }
      .message-quote-image-clearfix {
        clear: both;
      }
    }
  }
  // .message-image {
  //     .message-bubble-img-wrap {
  //         transition: background-image 3ms ease-in-out;
  //     }
  // }
  .message-more-btn {
    padding: 3px;
    i.f7-icons {
      color: rgb(138 138 138 / 16%);
      font-size: 23px;
    }
  }
}

.message-footer {
  margin-bottom: 0;
}

.message.had-contain-1-image .message-image {
  min-width: 15rem;
  // max-width: 60vw;
}

.message.had-contain-1-image .message-image .message-bubble-img-wrap {
  height: 15rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.message.had-contain-2-image .message-image {
  min-width: 15rem;
  // max-width: 50vw;
}

.message.had-contain-2-image .message-image .message-bubble-img-wrap {
  height: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 50%;
  float: left;
}

.message.had-contain-2-image
  .message-image
  .message-bubble-img-wrap:first-child {
  border-right: 1px solid #b1b1b1b8;
}

.message.had-contain-odd-image .message-image {
  min-width: 15rem;
  // max-width: 50vw;
}

.message.had-contain-odd-image .message-image .message-bubble-img-wrap {
  height: 8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 50%;
  float: left;
}

.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:first-child {
  width: 100%;
  height: 12rem;
}

.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:nth-child(even) {
  border-top: 1px solid #b1b1b1b8;
  border-right: 1px solid #b1b1b1b8;
}

.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:nth-child(odd) {
  border-top: 1px solid #b1b1b1b8;
}

.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:nth-child(2),
.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:nth-child(3) {
  border-top: none;
}

.message.had-contain-odd-image
  .message-image
  .message-bubble-img-wrap:nth-child(1) {
  border-bottom: 1px solid #b1b1b1b8;
  border-top: none;
}

.message.had-contain-even-image .message-image {
  min-width: 15rem;
  // max-width: 50vw;
}

.message.had-contain-even-image .message-image .message-bubble-img-wrap {
  height: 7rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 50%;
  float: left;
}

.message.had-contain-even-image
  .message-image
  .message-bubble-img-wrap:nth-child(odd) {
  border-top: 1px solid #b1b1b1b8;
  border-right: 1px solid #b1b1b1b8;
}

.message.had-contain-even-image
  .message-image
  .message-bubble-img-wrap:nth-child(even) {
  border-top: 1px solid #b1b1b1b8;
}

.message.had-contain-even-image
  .message-image
  .message-bubble-img-wrap:nth-child(1),
.message.had-contain-even-image
  .message-image
  .message-bubble-img-wrap:nth-child(2) {
  border-top: none;
}

.messages .message-footer .icon {
  font-size: 12px;
}

.messages.helpdesk-txt-color-red .message-sent .message-bubble,
.messages.helpdesk-text-red .message-sent .message-bubble,
.messages.color-helpdesk-text-red .message-sent .message-bubble {
  background-color: var(--ion-color-danger-tint) !important;
}

.message-sent .message-bubble a.autolink {
  color: #fff;
  text-decoration: underline;
}

.message-received .message-bubble a.autolink {
  color: var(--f7-message-received-text-color);
  text-decoration: underline;
}

.messagebar .messagebar-area {
  width: calc(100% - 72px);
}

@media (max-width: 768px) {
  .message {
    max-width: 90%;
  }
}

.theme-dark {
  .message {
    .message-more-btn {
      padding: 3px;
      i.f7-icons {
        color: rgb(138 138 138 / 45%);
      }
    }
  }
}

/** End messages */

/** Dialpad */

/* No CSS3 support: none */

@font-face {
  font-family: "Averta-Regular";
  // src: url("./Averta-Regular.eot");
  src: url("Averta-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Averta-Light";
  // src: url("./Averta-Light.eot");
  src: url("Averta-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Averta-Semibold";
  // src: url("./Averta-Semibold.eot");
  src: url("Averta-Semibold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* Home style */

.site-info {
  margin-top: 20px;
}

.site-logo {
  height: 100px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
}

.site-title {
  text-align: center;
  font-weight: bold;
}

.site-subtitle {
  text-align: center;
}


.notification-icon {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.notification-content {
  
}
.notification-title {
  opacity: 0.6;
}
.notification-moment {
  opacity: 0.5;
  font-size: 11px;
}

.notification-list {
  margin-left: calc(
    var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left)
  ) !important;
  margin-right: calc(
    var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-right)
  ) !important;
}

.notification-list ul:before,
.notification-list ul:after {
  height: 0 !important;
}

.notification.home-notification {
  position: inherit !important;
  margin-bottom: 10px !important;
  box-shadow: inherit !important;
  display: block !important;
  left: inherit !important;
  top: inherit !important;
  width: inherit !important;
  overflow: hidden !important;
  max-width: inherit !important;
}

.notification.home-notification.swipeout-deleting {
  padding: inherit !important;
}

.notification.home-notification-list.list ul:before {
  height: 0px !important;
}

.notification.home-notification-list.list ul:after {
  height: 0px !important;
}

@media (min-width: 568px) {
  .notification.home-notification {
    left: inherit !important;
    width: inherit !important;
    margin-left: inherit !important;
  }
}

/* End home*/

/* Helpdesk component*/

.helpdesk-txt-color-red,
.helpdesk-text-red,
.color-helpdesk-text-red {
  color: var(--ion-color-danger-tint) !important;
}

.text-color-editable {
  color: var(--f7-color-orange-tint) !important;
}

.red-link {
  color: red;
}

.framework7 .ios .message-bubble .message-bubble-img-wrap {
  width: 50%;
  height: 73px;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}

.framework7 .ios .message-bubble {
  min-width: 200px;
}

.framework7 .messages-title,
.framework7 .message {
  margin-top: 17px;
}

.helpdesk-list ul li i {
  font-size: 16px;
  width: 20px;
  text-align: left;
}

// .helpdesk-list ul li .item-subtitle,
// .helpdesk-list ul li .item-text {
//   /*display: flex;*/
//   /*align-items: center;*/
// }

.chat-list-item-label {
  display: flex;
  align-items: center;
  float: left;
}

/* End helpdesk component*/

.page[data-name="dialpad"] {
  /* CSS reset */
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    margin: 0 !important;
    padding: 0 !important;
  }
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
  }
  fieldset,
  img {
    border: 0 !important;
  }
  input {
    border: 1px solid #b0b0b0;
    padding: 3px 5px 4px;
    color: #979797;
    // width: 190px;
  }
  address,
  caption,
  cite,
  code,
  dfn,
  th,
  var {
    font-style: normal !important;
    font-weight: normal !important;
  }
  ol,
  ul {
    list-style: none !important;
  }
  caption,
  th {
    text-align: left !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  q:before,
  q:after {
    content: "" !important;
  }
  abbr,
  acronym {
    border: 0 !important;
  }
  /*# sourceMappingURL=reset.css.map */
  .text-center {
    text-align: center !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mb-5 {
    margin-bottom: 5px !important;
  }
  .mr-10 {
    margin-right: 10px !important;
  }
  .mr-20 {
    margin-right: 20px !important;
  }
  .mr-50 {
    margin-right: 50px !important;
  }
  .ml-10 {
    margin-left: 10px !important;
  }
  .ml-20 {
    margin-left: 20px !important;
  }
  .ml-50 {
    margin-left: 50px !important;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mb-20 {
    margin-bottom: 20px !important;
  }
  .mb-30 {
    margin-bottom: 30px !important;
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mt-15 {
    margin-top: 15px !important;
  }
  .mt-15-negative {
    margin-top: -15px !important;
  }
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-30 {
    margin-top: 30px !important;
  }
  .mt-50 {
    margin-top: 50px !important;
  }
  .mt-80 {
    margin-top: 80px !important;
  }
  .mt-100 {
    margin-top: 100px !important;
  }
  .p-0 {
    padding: 0px !important;
  }
  .p-5 {
    padding: 5px !important;
  }
  .p-10 {
    padding: 10px !important;
  }
  .p-15 {
    padding: 15px !important;
  }
  .p-20 {
    padding: 20px !important;
  }
  .p-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .p-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pb-10 {
    padding-bottom: 10px !important;
  }
  .pb-20 {
    padding-bottom: 20px !important;
  }
  .pt-10 {
    padding-top: 10px !important;
  }
  .pt-20 {
    padding-top: 20px !important;
  }
  .pl-15 {
    padding-left: 15px !important;
  }
  .pl-20 {
    padding-left: 20px !important;
  }
  .pr-15 {
    padding-right: 15px !important;
  }
  .pr-20 {
    padding-right: 20px !important;
  }
  .left-10 {
    left: 10px !important;
  }
  .top-0 {
    top: 0px !important;
  }
  .top-5 {
    top: 5px !important;
  }
  .top-10 {
    top: 10px !important;
  }
  .top-15 {
    top: 15px !important;
  }
  .top-20 {
    top: 20px !important;
  }
  .top-200 {
    top: 200px !important;
  }
  .bottom-0 {
    bottom: 0px !important;
  }
  .bottom-10 {
    bottom: 10px !important;
  }
  .width-100-percent {
    width: 100% !important;
  }
  .width-35 {
    width: 35px !important;
  }
  .width-50 {
    width: 50px !important;
  }
  .height-100-percent {
    height: 100% !important;
  }
  .height-100-vh {
    height: 100vh !important;
  }
  .height-30 {
    height: 30px !important;
  }
  .height-35 {
    height: 35px !important;
  }
  .height-40 {
    height: 40px !important;
  }
  .height-50 {
    height: 50px !important;
  }
  .height-350 {
    height: 350px !important;
  }
  .right-0 {
    right: 0px !important;
  }
  .right-15 {
    right: 15px !important;
  }
  .right-20 {
    right: 20px !important;
  }
  .right-30 {
    right: 30px !important;
  }
  .right-40 {
    right: 40px !important;
  }
  .display-none {
    display: none !important;
  }
  .display-initial {
    display: initial !important;
  }
  .display-table {
    display: table !important;
  }
  .display-flex {
    display: flex !important;
  }
  .justify-content-space-between {
    justify-content: space-between !important;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly !important;
  }
  .display-table-cell {
    display: table-cell;
  }
  .font-size-12 {
    font-size: 12px !important;
  }
  .font-size-16 {
    font-size: 16px !important;
  }
  .font-size-18 {
    font-size: 18px !important;
  }
  .font-size-20 {
    font-size: 20px !important;
  }
  .font-size-24 {
    font-size: 24px !important;
  }
  .font-size-25 {
    font-size: 25px !important;
  }
  .font-size-30 {
    font-size: 30px !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .outline-none {
    outline: none !important;
  }
  .z-index-10 {
    z-index: 10 !important;
  }
  .z-index-20 {
    z-index: 20 !important;
  }
  .z-index-100 {
    z-index: 100 !important;
  }
  .border-none {
    border: none !important;
  }
  .btn-round {
    -webkit-border-radius: 3rem !important;
    -moz-border-radius: 3rem !important;
    border-radius: 3rem !important;
  }
  .btn-round img {
    margin-top: 4px;
  }
  .border-radius-8 {
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
  }
  .border-radius-12 {
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
  }
  .border-radius-16 {
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    border-radius: 16px !important;
  }
  .border-radius-18 {
    -webkit-border-radius: 18px !important;
    -moz-border-radius: 18px !important;
    border-radius: 18px !important;
  }
  .color-white {
    color: #fff !important;
  }
  .color-black {
    color: #333 !important;
  }
  .bg-transparent {
    background: transparent !important;
  }
  .bg-red {
    background: #d80303 !important;
  }
  .bg-pink {
    background: #ff3366 !important;
  }
  .bg-black {
    background: #4e4e4e !important;
  }
  .bg-white {
    background: #fff !important;
  }
  .bg-gradient-green {
    background: linear-gradient(
      254.56deg,
      #66d54b 6.49%,
      #17c653 108.73%
    ) !important;
  }
  .bg-gradient-purple {
    /* Rectangle */
    background: linear-gradient(90deg, #818ff5 0%, #a0a4e9 93.03%) !important;
  }
  .bg-purple {
    background: #9498e1 !important;
  }
  .bg-light-gray {
    background: #f9fbfc !important;
  }
  .bg-light-green {
    background: #63dc93 !important;
  }
  .bg-light-blue {
    background: #56d7d0 !important;
  }
  .float-right {
    float: right !important;
  }
  .float-left {
    float: left !important;
  }
  .line-height-30 {
    line-height: 30px !important;
  }
  .line-height-26 {
    line-height: 26px !important;
  }
  .flex-basis {
    flex-basis: 100% !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .box-shadow1 {
    box-shadow: 0px 12px 45px rgba(51, 49, 140, 0.25) !important;
  }
  .box-shadow2 {
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05) !important;
  }
  .box-shadow3 {
    box-shadow: 0px 0px 15px rgba(51, 49, 140, 0.49) !important;
  }
  .btn {
    text-align: center !important;
  }
  .btn-red {
    background: linear-gradient(
      262.92deg,
      #ff5b65 6.49%,
      #ef3b67 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(176, 43, 67, 0.25) !important;
  }
  .btn-green {
    background: linear-gradient(
      262.92deg,
      #66d54b 6.49%,
      #17c653 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
  }
  .btn-size-25 {
    width: 25px !important;
    height: 25px !important;
  }
  .btn-size-28 {
    width: 30px !important;
    height: 30px !important;
  }
  .btn-size-30 {
    width: 30px !important;
    height: 30px !important;
  }
  .btn-size-35 {
    width: 35px !important;
    height: 35px !important;
  }
  .btn-size-55 {
    width: 55px !important;
    height: 55px !important;
  }
  .visibility-hidden {
    visibility: hidden !important;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .pointer-events-none {
    pointer-events: none !important;
  }
  /*# sourceMappingURL=util.css.map */
  .color-green {
    color: #2bb409 !important;
  }
  .color-red {
    color: #ff0000 !important;
  }
  .mt-150 {
    margin-top: 150px !important;
  }
  // @import url('util-select.css') !important;
  ::selection {
    background: transparent !important;
  }
  ::-moz-selection {
    background: transparent !important;
  }
  .wrapper-demo {
    margin: 60px 0 0 0 !important;
    *zoom: 1 !important;
    font-weight: 400 !important;
  }
  .wrapper-demo:after {
    clear: both !important;
    content: "" !important;
    display: table !important;
  }
  /* DEMO 1 */
  .wrapper-dropdown-1 {
    /* Size and position */
    position: relative !important;
    /* Enable absolute positionning for children and pseudo elements */
    width: 200px !important;
    padding: 10px !important;
    margin: 0 auto !important;
    /* Styles */
    background: #9bc7de !important;
    color: #fff !important;
    outline: none !important;
    cursor: pointer !important;
    /* Font settings */
    font-weight: bold !important;
  }
  .wrapper-dropdown-1:after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    right: 16px !important;
    top: 50% !important;
    margin-top: -6px !important;
    border-width: 6px 0 6px 6px !important;
    border-style: solid !important;
    border-color: transparent #fff !important;
  }
  .wrapper-dropdown-1 .dropdown {
    /* Size & position */
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    /* Styles */
    background: #fff !important;
    list-style: none !important;
    font-weight: normal !important;
    /* Cancels previous font-weight: bold !important; */
    /* Hiding */
    opacity: 0 !important;
    pointer-events: none !important;
  }
  .wrapper-dropdown-1 .dropdown li a {
    display: block !important;
    text-decoration: none !important;
    color: #9e9e9e !important;
    padding: 10px 20px !important;
  }
  /* Hover state */
  .wrapper-dropdown-1 .dropdown li:hover a {
    background: #f3f8f8 !important;
  }
  /* Active state */
  .wrapper-dropdown-1.active .dropdown {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  .wrapper-dropdown-1.active:after {
    border-color: #9bc7de transparent !important;
    border-width: 6px 6px 0 6px !important;
    margin-top: -3px !important;
  }
  .wrapper-dropdown-1.active {
    background: #9bc7de !important;
    background: -moz-linear-gradient(
      left,
      #9bc7de 0%,
      #9bc7de 78%,
      #ffffff 78%,
      #ffffff 100%
    ) !important;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, #9bc7de),
      color-stop(78%, #9bc7de),
      color-stop(78%, #ffffff),
      color-stop(100%, #ffffff)
    ) !important;
    background: -webkit-linear-gradient(
      left,
      #9bc7de 0%,
      #9bc7de 78%,
      #ffffff 78%,
      #ffffff 100%
    ) !important;
    background: -o-linear-gradient(
      left,
      #9bc7de 0%,
      #9bc7de 78%,
      #ffffff 78%,
      #ffffff 100%
    ) !important;
    background: -ms-linear-gradient(
      left,
      #9bc7de 0%,
      #9bc7de 78%,
      #ffffff 78%,
      #ffffff 100%
    ) !important;
    background: linear-gradient(
      to right,
      #9bc7de 0%,
      #9bc7de 78%,
      #ffffff 78%,
      #ffffff 100%
    ) !important;
    // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#9bc7de', endColorstr='#ffffff', GradientType=1) !important;
  }
  /* No CSS3 support */
  .no-opacity .wrapper-dropdown-1 .dropdown,
  .no-pointerevents .wrapper-dropdown-1 .dropdown {
    display: none !important;
    opacity: 1 !important;
    /* If opacity support but no pointer-events support */
    pointer-events: auto !important;
    /* If pointer-events support but no pointer-events support */
  }
  .no-opacity .wrapper-dropdown-1.active .dropdown,
  .no-pointerevents .wrapper-dropdown-1.active .dropdown {
    display: block !important;
  }
  /* DEMO 2 */
  .wrapper-dropdown-2 {
    /* Size and position */
    position: relative !important;
    /* Enable absolute positionning for children and pseudo elements */
    width: 200px !important;
    margin: 0 auto !important;
    padding: 10px 15px !important;
    /* Styles */
    background: #fff !important;
    border-left: 5px solid grey !important;
    cursor: pointer !important;
    outline: none !important;
  }
  .wrapper-dropdown-2:after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    right: 16px !important;
    top: 50% !important;
    margin-top: -3px !important;
    border-width: 6px 6px 0 6px !important;
    border-style: solid !important;
    border-color: grey transparent !important;
  }
  .wrapper-dropdown-2 .dropdown {
    /* Size & position */
    position: absolute !important;
    top: 100% !important;
    left: -5px !important;
    right: 0px !important;
    /* Styles */
    background: white !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
    list-style: none !important;
    /* Hiding */
    opacity: 0 !important;
    pointer-events: none !important;
  }
  .wrapper-dropdown-2 .dropdown li a {
    display: block !important;
    text-decoration: none !important;
    color: #333 !important;
    border-left: 5px solid !important;
    padding: 10px !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
  }
  .wrapper-dropdown-2 .dropdown li:nth-child(1) a {
    border-left-color: #00aced !important;
  }
  .wrapper-dropdown-2 .dropdown li:nth-child(2) a {
    border-left-color: #4183c4 !important;
  }
  .wrapper-dropdown-2 .dropdown li:nth-child(3) a {
    border-left-color: #3b5998 !important;
  }
  .wrapper-dropdown-2 .dropdown li i {
    margin-right: 5px !important;
    color: inherit !important;
    vertical-align: middle !important;
  }
  /* Hover state */
  .wrapper-dropdown-2 .dropdown li:hover a {
    color: grey !important;
  }
  /* Active state */
  .wrapper-dropdown-2.active:after {
    border-width: 0 6px 6px 6px !important;
  }
  .wrapper-dropdown-2.active .dropdown {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  /* No CSS3 support */
  .no-opacity .wrapper-dropdown-2 .dropdown,
  .no-pointerevents .wrapper-dropdown-2 .dropdown {
    display: none !important;
    opacity: 1 !important;
    /* If opacity support but no pointer-events support */
    pointer-events: auto !important;
    /* If pointer-events support but no pointer-events support */
  }
  .no-opacity .wrapper-dropdown-2.active .dropdown,
  .no-pointerevents .wrapper-dropdown-2.active .dropdown {
    display: block !important;
  }
  /* DEMO 3 */
  .wrapper-dropdown-3 {
    /* Size and position */
    position: relative !important;
    width: 200px !important;
    margin: 0 auto !important;
    padding: 10px !important;
    /* Styles */
    background: #fff !important;
    border-radius: 7px !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 1px 1px rgba(50, 50, 50, 0.1) !important;
    cursor: pointer !important;
    outline: none !important;
    /* Font settings */
    font-weight: bold !important;
    color: #8aa8bd !important;
  }
  .wrapper-dropdown-3:after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    right: 15px !important;
    top: 50% !important;
    margin-top: -3px !important;
    border-width: 6px 6px 0 6px !important;
    border-style: solid !important;
    border-color: #8aa8bd transparent !important;
  }
  .wrapper-dropdown-3 .dropdown {
    /* Size & position */
    position: absolute !important;
    top: 140% !important;
    left: 0 !important;
    right: 0 !important;
    /* Styles */
    background: white !important;
    border-radius: inherit !important;
    border: 1px solid rgba(0, 0, 0, 0.17) !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
    font-weight: normal !important;
    -webkit-transition: all 0.5s ease-in !important;
    -moz-transition: all 0.5s ease-in !important;
    -ms-transition: all 0.5s ease-in !important;
    -o-transition: all 0.5s ease-in !important;
    transition: all 0.5s ease-in !important;
    list-style: none !important;
    /* Hiding */
    opacity: 0 !important;
    pointer-events: none !important;
  }
  .wrapper-dropdown-3 .dropdown:after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    bottom: 100% !important;
    right: 15px !important;
    border-width: 0 6px 6px 6px !important;
    border-style: solid !important;
    border-color: #fff transparent !important;
  }
  .wrapper-dropdown-3 .dropdown:before {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    bottom: 100% !important;
    right: 13px !important;
    border-width: 0 8px 8px 8px !important;
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.1) transparent !important;
  }
  .wrapper-dropdown-3 .dropdown li a {
    display: block !important;
    padding: 10px !important;
    text-decoration: none !important;
    color: #8aa8bd !important;
    border-bottom: 1px solid #e6e8ea !important;
    box-shadow: inset 0 1px 0 white !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
  }
  .wrapper-dropdown-3 .dropdown li i {
    float: right !important;
    color: inherit !important;
  }
  .wrapper-dropdown-3 .dropdown li:first-of-type a {
    border-radius: 7px 7px 0 0 !important;
  }
  .wrapper-dropdown-3 .dropdown li:last-of-type a {
    border: none !important;
    border-radius: 0 0 7px 7px !important;
  }
  /* Hover state */
  .wrapper-dropdown-3 .dropdown li:hover a {
    background: #f3f8f8 !important;
  }
  /* Active state */
  .wrapper-dropdown-3.active .dropdown {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  /* No CSS3 support */
  .no-opacity .wrapper-dropdown-3 .dropdown,
  .no-pointerevents .wrapper-dropdown-3 .dropdown {
    display: none !important;
    opacity: 1 !important;
    /* If opacity support but no pointer-events support */
    pointer-events: auto !important;
    /* If pointer-events support but no pointer-events support */
  }
  .no-opacity .wrapper-dropdown-3.active .dropdown,
  .no-pointerevents .wrapper-dropdown-3.active .dropdown {
    display: block !important;
  }
  /* DEMO 4 */
  .wrapper-dropdown-4 {
    /* Size and position */
    position: relative !important;
    width: 270px !important;
    margin: 0 auto !important;
    padding: 10px 10px 10px 30px !important;
    /* Styles */
    background: #fff !important;
    border: 1px solid silver !important;
    cursor: pointer !important;
    outline: none !important;
  }
  .wrapper-dropdown-4:after {
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    right: 10px !important;
    top: 50% !important;
    margin-top: -3px !important;
    border-width: 6px 6px 0 6px !important;
    border-style: solid !important;
    border-color: #ffaa9f transparent !important;
  }
  .wrapper-dropdown-4 .dropdown {
    /* Size & position */
    position: absolute !important;
    top: 100% !important;
    margin-top: 1px !important;
    left: -1px !important;
    right: -1px !important;
    /* Styles */
    background: white !important;
    border: inherit !important;
    border-top: none !important;
    list-style: none !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
    /* Hiding */
    opacity: 0 !important;
    pointer-events: none !important;
  }
  /* Red lines: the pseudo-elements way */
  .wrapper-dropdown-4 .dropdown:before,
  .wrapper-dropdown-4:before {
    content: "" !important;
    width: 4px !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 15px !important;
    border: 1px solid #ffaa9f !important;
    border-top: none !important;
    border-bottom: none !important;
    z-index: 2 !important;
  }
  /* Red lines: the gradients way */
  /*
.wrapper-dropdown-4 .dropdown,
.wrapper-dropdown-4 {
  background: linear-gradient(left, white 5%, #ffaa9f 5%, #ffaa9f 5.3%, white 5.3%, white 6.5%, #ffaa9f 6.5%, #ffaa9f 6.8%, white 6.8%) !important;
}

.wrapper-dropdown-4 .dropdown li:hover label {
  background: linear-gradient(left, #f0F0F0 5%, #ffaa9f 5%, #ffaa9f 5.3%, #f0F0F0 5.3%, #f0F0F0 6.5%, #ffaa9f 6.5%, #ffaa9f 6.8%, #f0F0F0 6.8%) !important;
}
*/
  .wrapper-dropdown-4 .dropdown li {
    position: relative !important;
    /* Enable absolute positioning for checkboxes */
  }
  .wrapper-dropdown-4 .dropdown li label {
    display: block !important;
    padding: 10px 10px 10px 30px !important;
    /* Same padding as the button */
    border-bottom: 1px dotted #1ccfcf !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
  }
  .wrapper-dropdown-4 .dropdown li:last-of-type label {
    border: none !important;
  }
  .wrapper-dropdown-4 .dropdown li input {
    position: absolute !important;
    display: block !important;
    right: 10px !important;
    top: 50% !important;
    margin-top: -8px !important;
  }
  /* Hover state */
  .wrapper-dropdown-4 .dropdown li:hover label {
    background: #f0f0f0 !important;
  }
  /* Checked state */
  .wrapper-dropdown-4 .dropdown li input:checked ~ label {
    color: grey !important;
    text-decoration: line-through !important;
  }
  /* Active state */
  .wrapper-dropdown-4.active:after {
    border-width: 0 6px 6px 6px !important;
  }
  .wrapper-dropdown-4.active .dropdown {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  /* No CSS3 support */
  .no-opacity wrapper-dropdown-4 .dropdown,
  .no-pointerevents .wrapper-dropdown-4 .dropdown {
    display: none !important;
    opacity: 1 !important;
    /* If opacity support but no pointer-events support */
    pointer-events: auto !important;
    /* If pointer-events support but no pointer-events support */
  }
  .no-opacity .wrapper-dropdown-4.active .dropdown,
  .no-pointerevents .wrapper-dropdown-4.active .dropdown {
    display: block !important;
  }
  /* DEMO 5 */
  .wrapper-dropdown-5 {
    /* Size & position */
    position: relative !important;
    width: 200px !important;
    margin: 0 auto !important;
    padding: 12px 15px !important;
    /* Styles */
    background: #fff !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) !important;
    cursor: pointer !important;
    outline: none !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
  }
  .wrapper-dropdown-5:after {
    /* Little arrow */
    content: "" !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    top: 50% !important;
    right: 15px !important;
    margin-top: -3px !important;
    border-width: 6px 6px 0 6px !important;
    border-style: solid !important;
    border-color: #4cbeff transparent !important;
  }
  .wrapper-dropdown-5 .dropdown {
    /* Size & position */
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    /* Styles */
    background: #fff !important;
    border-radius: 0 0 5px 5px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-top: none !important;
    border-bottom: none !important;
    list-style: none !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    -ms-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
    /* Hiding */
    max-height: 0 !important;
    overflow: hidden !important;
  }
  .wrapper-dropdown-5 .dropdown li {
    padding: 0 10px !important;
  }
  .wrapper-dropdown-5 .dropdown li a {
    display: block !important;
    text-decoration: none !important;
    color: #333 !important;
    padding: 10px 0 !important;
    transition: all 0.3s ease-out !important;
    border-bottom: 1px solid #e6e8ea !important;
  }
  .wrapper-dropdown-5 .dropdown li:last-of-type a {
    border: none !important;
  }
  .wrapper-dropdown-5 .dropdown li i {
    margin-right: 5px !important;
    color: inherit !important;
    vertical-align: middle !important;
  }
  /* Hover state */
  .wrapper-dropdown-5 .dropdown li:hover a {
    color: #57a9d9 !important;
  }
  /* Active state */
  .wrapper-dropdown-5.active {
    border-radius: 5px 5px 0 0 !important;
    background: #4cbeff !important;
    box-shadow: none !important;
    border-bottom: none !important;
    color: white !important;
  }
  .wrapper-dropdown-5.active:after {
    border-color: #82d1ff transparent !important;
  }
  .wrapper-dropdown-5.active .dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    max-height: 400px !important;
  }
  * {
    outline: none !important;
  }
  button {
    cursor: pointer !important;
    line-height: initial !important;
    width: initial !important;
  }
  .action-call:hover {
    background: #f6fbfb !important;
  }
  .action-call:hover .drop-down-action {
    display: initial !important;
  }
  .action-call.active .icon {
    display: none !important;
  }
  .action-call.active .icon-on {
    display: initial !important;
  }
  .bg-transparent .toolbar-item:hover {
    background: #acb1f3 !important;
  }
  .toolbar-item:hover {
    background: #f6fbfb !important;
  }
  .diapad-key:hover {
    background: #f2f7fe !important;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e9e9f3 !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #e9e9f3 !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #e9e9f3 !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #e9e9f3 !important;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px !important;
    background-color: #e9e9e9 !important;
    background-clip: content-box !important;
    /* THIS IS IMPORTANT */
  }
  ::-webkit-scrollbar {
    width: 6px !important;
    /*background-color: #bababa !important;*/
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #c5c5c5 !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #8c8c8c !important;
  }
  .contact-item-call button {
    transition: none !important;
  }
  .btn-call-item {
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
  .btn-call-item img {
    width: 12px !important;
  }
  #stringee_clound {
    font-family: "Averta-Regular" !important;
    /* Rectangle */
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    background: #ffffff !important;
    // border-radius: 18px !important;
  }
  #app {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    background: #fff !important;
  }
  .status-text {
    /* Currently in call.. */
    width: 100px !important;
    height: 15px !important;
    line-height: normal !important;
    font-size: 12px !important;
    letter-spacing: 0.03em !important;
    color: #bfbfc4 !important;
  }
  .wrap-status-time {
    width: 80px !important;
    height: 33px !important;
    background: linear-gradient(
      254.56deg,
      #66d54b 6.49%,
      #17c653 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
    border-radius: 12px 0px 0px 12px !important;
    padding-left: 15px !important;
  }
  .wrap-status-time.had-waiting-incoming-call {
    // background: linear-gradient(254.56deg, #f93232 6.49%, #fb6969 108.73%) !important;
    animation: mymove 1s infinite !important;
  }
  @keyframes mymove {
    from {
      background: linear-gradient(
        254.56deg,
        #66d54b 6.49%,
        #17c653 108.73%
      ) !important;
    }
    to {
      background: linear-gradient(
        254.56deg,
        #f93232 6.49%,
        #fb6969 108.73%
      ) !important;
    }
  }
  .wrap-agent-time {
    /* Rectangle 2.3 */
    width: 74px !important;
    height: 33px !important;
    background: linear-gradient(
      254.56deg,
      #f93232 6.49%,
      #fb6969 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
    border-radius: 12px 0px 0px 12px !important;
  }
  .status-time {
    /* 00:08 */
    width: 35px !important;
    height: 15px !important;
    line-height: normal !important;
    font-size: 12px !important;
    letter-spacing: 0.03em !important;
    color: #ffffff !important;
  }
  .agent-time {
    width: 35px !important;
    height: 15px !important;
    line-height: normal !important;
    font-size: 12px !important;
    letter-spacing: 0.03em !important;
    margin-left: 10px !important;
    color: #ffffff !important;
  }
  .line-red-short {
    /* Rectangle 3.3 */
    position: absolute !important;
    width: 30px !important;
    height: 2px !important;
    background: #ff5b65 !important;
    border-radius: 12px !important;
  }
  .toast {
    background-color: #e9e9e9 !important;
    position: fixed !important;
    top: 0px !important;
    width: 95% !important;
    z-index: 9 !important;
    color: white !important;
    padding: 8px !important;
    height: auto !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    border-radius: 10px 10px 0px 0px !important;
    margin: 0px auto !important;
    right: 0px !important;
    left: 0px !important;
  }
  .toast.toast-error {
    background-color: #f44336 !important;
  }
  .toast.toast-success {
    background-color: #4bcc50 !important;
  }
  .toast.toast-warning {
    background-color: #ffc107 !important;
  }
  .info-name {
    /* Pattrick Penna */
    font-size: 24px !important;
    color: #333333 !important;
  }
  .info-name a.contact-info-name {
    text-decoration: none !important;
    border-bottom: 1px dashed #bebebe !important;
    color: #4a4a4a !important;
  }
  .info-name a.contact-info-name:hover {
    font-weight: 550 !important;
  }
  .callstart-time {
    text-align: center !important;
  }
  .location-via {
    font-size: 12px !important;
    color: #bfbfc4 !important;
  }
  .location-text {
    color: #7272aa !important;
  }
  .location-queue {
    font-size: 16px !important;
    color: #f7f7ec !important;
  }
  .location-queuetime {
    font-size: 15px !important;
    color: #f7f7ec !important;
  }
  .line-dotted {
    /* Line */
    padding-top: 16px !important;
    height: 1px !important;
    width: 100% !important;
    border-bottom: 1px dotted #ebedf7 !important;
  }
  .wrap-avatar-round img {
    border-radius: 36px !important;
    border: 4px solid #fff !important;
    width: 30% !important;
    margin-top: 20px !important;
  }
  .wrap-toolbar .toolbar-item {
    padding: 15px !important;
  }
  .dialpad-page {
    position: absolute !important;
    left: 0px !important;
    // top: 40px !important;
    top: 20px !important;
    width: 100% !important;
    height: calc(100% - 40px - 50px) !important;
  }
  .incomming-call-info {
    width: 90% !important;
    margin-left: 5% !important;
    height: 80% !important;
  }
  .incomming-call-action {
    width: 90% !important;
    margin-left: 5% !important;
  }
  .diapad-row {
    display: flex !important;
    justify-content: space-between !important;
  }
  .diapad-key {
    width: 100% !important;
    border: none !important;
    background: #fff !important;
  }
  .diapad-key-number {
    font-family: "Averta-Light" !important;
    display: block !important;
    font-size: 30px !important;
    color: #3583de !important;
  }
  .diapad-key-text {
    font-family: Helvetica Neue !important;
    font-size: 10px !important;
    color: #adb0da !important;
    display: block !important;
    height: 20px !important;
  }
  .call-using-text {
    font-family: Averta-Semibold !important;
    font-size: 10px !important;
    color: #b8c9e2 !important;
  }
  .call-using-select {
    background: #f2f7fe !important;
    border-radius: 8px !important;
  }
  .call-using-text-name {
    font-family: Averta-Semibold !important;
    font-size: 14px !important;
    display: block !important;
  }
  .call-using-text-phone {
    font-size: 12px !important;
    color: #8e9ccc !important;
  }
  .call-using-dropdown {
    max-height: 180px !important;
    overflow-y: auto !important;
    position: absolute !important;
    width: calc(100% - 40px) !important;
    left: 20px !important;
    bottom: -8px !important;
    z-index: 100 !important;
  }
  .call-using-dropdown .call-using-dropdown-item {
    border-bottom: 1px dashed #ebedf7 !important;
  }
  .call-using-dropdown .call-using-dropdown-item:last-child {
    border-bottom: none !important;
  }
  .call-using-dropdown .call-using-dropdown-item:hover {
    background: #f2f7fe !important;
  }
  .wrap-call-using-dropdown .icon-dropdown {
    position: absolute !important;
    z-index: 120 !important;
    bottom: -16px !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 8px solid #fff !important;
  }
  .contact-filter-item {
    border-radius: 12px !important;
    background: #dbdbe3 !important;
    color: #fff !important;
  }
  .contact-filter-item.active {
    background: #a1a5ea !important;
  }
  .input-search {
    width: 199px !important;
    height: 36px !important;
    background: #e7ecef !important;
    border-radius: 30px 0px 0px 30px !important;
    font-family: "Averta-Regular" !important;
    font-size: 15px !important;
    color: #000 !important;
  }
  .wrap-contact-list {
    height: 395px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    visibility: hidden !important;
  }
  .wrap-contact-list:hover {
    visibility: visible !important;
  }
  .wrap-contact-list-content {
    visibility: visible !important;
  }
  .contact-group-item {
    border: 1px dotted #dbdfed !important;
    border-right: none !important;
    border-radius: 18px 0px 0px 18px !important;
    background: #f9fbfc !important;
  }
  .contact-group-item .contact-item:last-child {
    border-bottom: none !important;
  }
  .wrap-contact-item-avatar {
    border: 2px solid #fff !important;
    position: relative !important;
    -moz-box-shadow: 0 0 20px #e0f3ec !important;
    -webkit-box-shadow: 0 0 20px #e0f3ec !important;
    box-shadow: 0 0 20px #e0f3ec !important;
  }
  .contact-group-name {
    font-size: 12px !important;
    color: #9eb1d6 !important;
    padding: 10px !important;
  }
  .contact-item-status {
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    top: -2px !important;
    right: -2px !important;
    position: absolute !important;
    background: #e0e0e0 !important;
  }
  .status-online {
    background: #4feea7 !important;
  }
  .status-offline {
    background: #ff5b6d !important;
  }
  .contact-item {
    padding: 10px !important;
    padding-left: 0px !important;
    margin-left: 15px !important;
    border-bottom: 1px dashed rgba(51, 49, 140, 0.25) !important;
    position: relative !important;
  }
  .contact-item:hover {
    cursor: pointer !important;
    margin-left: -20px !important;
    border-bottom: none !important;
    zoom: 1.2 !important;
    margin-top: -2px !important;
    background: #fff !important;
    border-radius: 24px 0px 0px 24px !important;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08) !important;
    padding-left: 10px !important;
  }
  .contact-item:hover .visibility-hidden {
    visibility: visible !important;
  }
  .wrap-contact-item-info {
    padding-left: 10px !important;
  }
  .contact-item-name {
    font-size: 14px !important;
    color: #333 !important;
  }
  .contact-item-phone {
    font-size: 12px !important;
    color: #b6b6c4 !important;
  }
  .contact-item-call {
    position: absolute !important;
    right: 5px !important;
    top: 15px !important;
  }
  .wrap-activity-list {
    height: 428px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    visibility: hidden !important;
  }
  .wrap-activity-list:hover {
    visibility: visible !important;
  }
  .wrap-activity-list-content {
    visibility: visible !important;
  }
  .activity-date {
    color: #cfd0dc !important;
    font-size: 10px !important;
    margin-bottom: 5px !important;
    margin-left: 20px !important;
    margin-top: 10px !important;
  }
  .wrap-activity-item {
    padding: 10px 20px !important;
    cursor: pointer !important;
    position: relative !important;
    background: #f9fbfc !important;
  }
  .wrap-activity-group {
    width: calc(100% - 25px) !important;
    margin-bottom: 10px !important;
  }
  .wrap-activity-group .wrap-activity-item {
    border: 1px solid #eff1f6 !important;
    border-bottom: none !important;
  }
  .wrap-activity-group .wrap-activity-item:first-child {
    border-radius: 0px 6px 0px 0px !important;
  }
  .wrap-activity-group .wrap-activity-item:last-child {
    border-radius: 0px 0px 6px 0px !important;
    border-bottom: 1px solid #eff1f6 !important;
  }
  .wrap-activity-group .wrap-activity-item:hover {
    width: 100% !important;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08) !important;
    background: #fff !important;
  }
  .wrap-activity-group .wrap-activity-item:hover .activity-more {
    right: 27px !important;
  }
  .activity-icon {
    margin-right: 10px !important;
  }
  .activity-info {
    width: 140px !important;
  }
  .activity-phone {
    font-size: 13px !important;
  }
  .activity-via {
    font-size: 10px !important;
    color: #b6b6c4 !important;
  }
  .activity-time {
    font-size: 10px !important;
    color: #c9c9da !important;
  }
  .activity-more {
    position: absolute !important;
    right: -15px !important;
  }
  .wrap-toolbar-bottom .icon {
    display: none;
  }
  .wrap-toolbar-bottom i.icon {
    font-size: 20px;
  }
  .wrap-toolbar-bottom .icon.icon-gray {
    display: initial;
  }
  .wrap-toolbar-bottom.bg-transparent {
    background: transparent !important;
  }
  .wrap-toolbar-bottom.bg-transparent .icon {
    display: initial !important;
  }
  .wrap-toolbar-bottom.bg-transparent .icon-gray {
    display: none !important;
  }
  #page-incomming-call {
    height: calc(100% - 30px) !important;
  }
  .drop-down-action {
    position: absolute !important;
    width: 148px !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 8px rgba(83, 92, 198, 0.15) !important;
    border-radius: 12px !important;
    right: 10px !important;
    top: 38px !important;
    font-family: Averta-Regular !important;
    line-height: 39px !important;
    font-size: 15px !important;
    color: #4f4f4f !important;
    padding: 15px !important;
  }
  .drop-down-action ul li {
    display: flex !important;
  }
  .drop-down-action ul li img {
    margin-right: 10px !important;
  }
  .drop-down-action ul li:hover {
    color: #6154ee !important;
  }
  .drop-down-action ul li:hover .icon {
    display: none !important;
  }
  .drop-down-action ul li:hover .icon-hover {
    display: initial !important;
  }
  .drop-down-rectangle {
    position: absolute !important;
    width: 13px !important;
    height: 13px !important;
    background: #ffffff !important;
    transform: rotate(45deg) !important;
    top: -5px !important;
    right: 10px !important;
  }
  .top-bar-title {
    color: #4f4f4f !important;
    font-size: 12px !important;
    margin-top: 34px !important;
  }
  .mode-minimize {
    width: 206px !important;
    height: 44px !important;
    border-radius: 8px !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    display: block !important;
    background: transparent !important;
    position: fixed !important;
    z-index: 16000015 !important;
    border-radius: 10px !important;
    width: 294px !important;
    height: 556px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #e9ebed !important;
    box-sizing: content-box !important;
    top: 50px !important;
    left: 10px !important;
  }
  .mode-minimize #app {
    display: none !important;
  }
  .mode-minimize #app-minimize {
    display: block !important;
  }
  .app-minimize-card {
    display: none !important;
  }
  .mode-minimize .app-minimize-card {
    display: block !important;
  }
  #app-minimize {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    background: #fff !important;
    display: none !important;
  }
  .wrap-info-minimize {
    width: calc(206px - 30px) !important;
    margin-left: 10px !important;
    margin-top: 9px !important;
  }
  .wrap-info-minimize .time {
    padding: 3px 12px !important;
  }
  .line-vertical {
    width: 1px !important;
    border-right: 1px solid #f5f7ff !important;
  }
  /*# sourceMappingURL=style.css.map */
  .top-bar-status {
    position: absolute !important;
    font-size: 12px !important;
    top: 12px !important;
    left: 20px !important;
  }
  .wrap-option-call {
    padding: 20px !important;
    height: 100% !important;
    background: #fffffff2 !important;
  }
  .wrap-option-call .btn-otption-call {
    width: 100% !important;
    height: 50px !important;
    font-size: 14px !important;
    margin-bottom: 20px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    color: #fff !important;
    border: none !important;
    position: relative !important;
  }
  .wrap-option-call .btn-icon {
    position: absolute !important;
    left: 25px !important;
    width: 28px !important;
    height: 28px !important;
    top: 11px !important;
  }
  .wrap-option-call .btn-icon img {
    margin-top: 7px !important;
  }
  .btn-free-voice-call {
    background: linear-gradient(
      262.92deg,
      #3fc635 6.49%,
      #3fb929 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
  }
  .btn-free-video-call {
    background: linear-gradient(
      262.92deg,
      #3fc7fd 6.49%,
      #2ba7fc 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
  }
  .btn-free-callout {
    background: linear-gradient(
      262.92deg,
      #b589d3 6.49%,
      #9a5ac6 108.73%
    ) !important;
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25) !important;
  }
  .wrap-action-top-right button img {
    vertical-align: middle !important;
  }
  .wrapper-dropdown-call {
    float: left !important;
  }
  .wrapper-dropdown-call.auto {
    float: right !important;
  }
  .wrapper-dropdown-call #dropdown-option-call,
  .wrapper-dropdown-call #dropdown-auto-receive-call {
    width: 30px !important;
    font-size: 12px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 5px !important;
  }
  .wrapper-dropdown-call #dropdown-option-call:after,
  .wrapper-dropdown-call #dropdown-auto-receive-call:after {
    border-color: #ccc transparent !important;
    right: 10px !important;
    border-width: 4px 4px 0 4px !important;
  }
  .wrapper-dropdown-call #dropdown-option-call .icon-option-active,
  .wrapper-dropdown-call #dropdown-auto-receive-call .icon-option-active {
    width: 14px !important;
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
  .wrapper-dropdown-call .dropdown {
    left: initial !important;
    right: -50px !important;
    width: 250px !important;
    z-index: 1 !important;
    top: 35px !important;
    -webkit-transition: all 0.2s ease-in !important;
    -moz-transition: all 0.2s ease-in !important;
    -ms-transition: all 0.2s ease-in !important;
    -o-transition: all 0.2s ease-in !important;
    transition: all 0.2s ease-in !important;
  }
  .wrapper-dropdown-call.auto .dropdown {
    width: 200px !important;
    right: 0px !important;
    min-height: 70px !important;
  }
  .wrapper-dropdown-call.auto .dropdown:after {
    right: 6px !important;
  }
  .wrapper-dropdown-call.auto .dropdown:before {
    right: 4px !important;
  }
  .setting-agent li {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 5px !important;
    font-size: 14px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.17) !important;
    margin: 4px !important;
    cursor: pointer !important;
  }
  .setting-agent li:last-child {
    border: none !important;
  }
  .setting-agent li.disabled {
    background: #f2f7fe !important;
  }
  .setting-agent li input {
    width: auto !important;
  }
  .setting-agent li label {
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  input[type="checkbox"] {
    position: relative !important;
    cursor: pointer !important;
  }
  input[type="checkbox"]:before {
    content: "" !important;
    display: block !important;
    position: absolute !important;
    width: 12px !important;
    height: 12px !important;
    top: 0 !important;
    left: 0 !important;
    border: 1px solid #0000002b !important;
    border-radius: 3px !important;
    background-color: white !important;
  }
  #autopickcheckbox:disabled:before {
    background-color: #f2f7fe !important;
  }
  input[checked="checked"]:after {
    content: "" !important;
    display: block !important;
    width: 5px !important;
    height: 9px !important;
    border: solid #8aa8bd !important;
    border-width: 0 2px 2px 0 !important;
    -webkit-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: 0px !important;
    left: 4px !important;
  }
  .wrapper-dropdown-call .dropdown .icon-option {
    width: 13px !important;
    vertical-align: middle !important;
    margin-right: 5px !important;
  }
  .wrapper-dropdown-call .dropdown li a {
    padding: 8px 12px !important;
  }
  .wrapper-dropdown-call .dropdown:after {
    right: 70px !important;
  }
  .wrapper-dropdown-call .dropdown:before {
    right: 68px !important;
  }
  .diapad-size-medium .diapad-key {
    height: 48px !important;
  }
  #page-calling.diapad-when-calling .wrap-background {
    display: none !important;
  }
  #page-calling.diapad-when-calling .wrap-diapad-when-calling {
    display: block !important;
  }
  input::selection {
    background: #f2f7fe !important;
  }
  input::-moz-selection {
    background: #f2f7fe !important;
  }
  .switch {
    position: relative !important;
    display: inline-block !important;
    width: 60px !important;
    height: 34px !important;
  }
  .switch input {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
  .slider {
    position: absolute !important;
    cursor: pointer !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: #ccc !important;
    -webkit-transition: 0.4s !important;
    transition: 0.4s !important;
  }
  .slider:before {
    position: absolute !important;
    content: "" !important;
    height: 26px !important;
    width: 26px !important;
    left: 4px !important;
    bottom: 4px !important;
    background-color: white !important;
    -webkit-transition: 0.4s !important;
    transition: 0.4s !important;
  }
  input:checked + .slider {
    background-color: #2196f3 !important;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3 !important;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px) !important;
    -ms-transform: translateX(26px) !important;
    transform: translateX(26px) !important;
  }
  .no-recent-call {
    width: 100% !important;
    text-align: center !important;
    color: #ff0000 !important;
    font-size: 12px !important;
    padding-top: 15px !important;
  }
  /*# sourceMappingURL=style.css.map */
  #btnToolCall.btn-red:disabled,
  #btnToolCall.btn-red[disabled] {
    background: linear-gradient(
      262.92deg,
      #94343a 6.49%,
      #94343a 108.73%
    ) !important;
  }
  #btn-incomming-decline:disabled,
  #btn-incomming-decline[disabled] {
    background: linear-gradient(
      262.92deg,
      #94343a 6.49%,
      #94343a 108.73%
    ) !important;
  }
  #btn-incomming-accept:disabled,
  #btn-incomming-accept[disabled] {
    background: linear-gradient(
      262.92deg,
      #336b25 6.49%,
      #336b25 108.73%
    ) !important;
  }
  #btnToolCall.btn-green:disabled,
  #btnToolCall.btn-green[disabled] {
    background: linear-gradient(
      262.92deg,
      #40882f 6.49%,
      #40882f 108.73%
    ) !important;
  }
  .top-bar-title {
    font-size: 14px !important;
  }
  .min-no-calls {
    color: #525252 !important;
    width: 176px !important;
    text-align: center !important;
    padding-top: 3px !important;
  }
}

/** End Dialpad*/

/** Chat room list style*/

.helpdesk-txt-color-red,
.messages.helpdesk-text-red,
.messages.color-helpdesk-text-red,
.helpdesk-text-red .actions-button-media i.icon {
  color: var(--ion-color-danger-tint) !important;
}

.color-helpdesk-text-red .actions-button-media i.icon {
  color: var(--ion-color-danger-tint) !important;
}

.red-link {
  color: red;
}

.framework7 .ios .message-bubble .message-bubble-img-wrap {
  width: 50%;
  height: 73px;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}

.framework7 .ios .message-bubble {
  min-width: 200px;
}

.framework7 .messages-title,
.framework7 .message {
  margin-top: 17px;
}

.workplace-list ul li i {
  font-size: 16px;
  width: 20px;
  text-align: left;
}

// .workplace-list ul li .item-subtitle,
// .workplace-list ul li .item-text {
//   /*display: flex;*/
//   /*align-items: center;*/
// }

.list ul li .badge:empty {
  display: none;
}

.chat-list-item-label {
  display: flex;
  align-items: center;
  float: left;
}

@keyframes showChatRoomMembers {
  from {transform: translateY(-80px);}
  to {transform: translateY(0%);}
}
.chat-room-member-wrap {
  // top: 70px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;

  .members {
    padding: 5px;
      background: var(--f7-message-received-text-color);
      border-radius: 16px;
      .member {
        width: 24px; 
        height: 24px; 
        margin-right: 3px; 
        float: left;
        overflow: hidden; 
        border-radius: 50%; 
        background-repeat: no-repeat; 
        background-color: gray; 
        background-size: cover; 
      }
  }
}
.chat-room-member-wrap.show {
  animation-name: showChatRoomMembers;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.42, 0, 0.42, 1.48);
}
// .page-content.embed-barcode-scanner {
//   margin-top: 200px;
//   height: calc(100% - 200px);
//   background: transparent;
// }

/** End chat room list style*/

/** Mentions input */

// .memtions-mask {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     flex-shrink: 1;
//     background-color: var(--f7-messagebar-textarea-bg-color);
//     padding: var(--f7-messagebar-textarea-padding);
//     height: var(--f7-messagebar-textarea-height);
//     color: var(--f7-messagebar-textarea-text-color);
//     font-size: var(--f7-messagebar-textarea-font-size);
//     line-height: var(--f7-messagebar-textarea-line-height);
//     border: transparent;
//     color: transparent;
// }
// .memtions-mask strong {
//     font-weight: normal;
//     background: #d8dfea;
// }
// .messagebar textarea {
//     position: absolute;
//     bottom: 0;
// }
// .messagebar .messagebar-area {
//     height: 34px;
// }
.messagebar .toolbar-inner {
  overflow: initial;
}

.messagebar .messagebar-area {
  overflow: initial;
}

.mentions-input-box {
  background: transparent !important;
}

.mentions-input-box .mentions {
  line-height: var(--f7-messagebar-textarea-line-height);
  color: transparent;
}

.messagebar textarea {
  min-height: 40px;
  padding: 9px;
}

.mentions-input-box .mentions-autocomplete-list {
  // display: none;
  background: transparent !important;
  border: initial !important;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: initial !important;
  // border-top-right-radius: initial !important;
  // border-top-left-radius: initial !important;
  box-shadow: initial !important;
  top: 2px;
  height: 1px;
  overflow: initial;
}

.mentions-input-box .mentions-autocomplete-list ul {
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid var(--f7-list-chevron-icon-color);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10000;
  margin-top: -2px;
  border-radius: 5px;
  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
  box-shadow: initial;
  overflow: hidden;
}

.mentions-input-box .mentions-autocomplete-list li {
  // background: transparent !important;
  border-bottom: 1px solid var(--f7-list-chevron-icon-color);
  color: var(--f7-color-black-tint) !important;
  height: 35px !important;
  line-height: 35px !important;
}

.mentions-input-box
  .mentions-autocomplete-list
  li[data-ref-type="GROUP"]
  .icon {
  display: none;
}

.mentions-input-box .mentions-autocomplete-list li:hover,
.mentions-input-box .mentions-autocomplete-list li.active {
  background-color: var(--f7-card-footer-border-color) !important;
}

.mentions-input-box .mentions > div > strong {
  border-radius: 3px;
}

.messagebar .message-quote {
  padding: 5px;
  border-radius: var(--f7-messagebar-textarea-border-radius)
    var(--f7-messagebar-textarea-border-radius) 0 0;
  border: 1px solid var(--f7-messagebar-attachments-border-color);
  font-style: italic;
  border-bottom: none;
  max-height: 7rem;
  overflow: hidden;
  > span {
    font-size: 14px;
    color: #888;
  }
  hr {
    background-color: var(--f7-messagebar-attachments-border-color);
    margin: 3px;
    margin-left: -5px;
    margin-right: -5px;
  }
  .message-quote-from-name {
    font-weight: bold;
    color: var(--f7-theme-color);
  }
  .message-quote-time {
    float: right;
    color: #888;
    font-size: 11px;
  }
  .message-quote-icon {
    font-size: 17px;
  }
  .message-quote-attachments {
    img {
      width: 32px;
      height: 32px;
      border-radius: 20%;
      margin: 3px;
    }
  }
  & + .mentions-input-box .messagebar-attachments {
    border-radius: 0;
  }
  & + .mentions-input-box textarea {
    border-radius: 0 0 var(--f7-messagebar-textarea-border-radius)
      var(--f7-messagebar-textarea-border-radius);
  }
  .message-quote-close {
    float: right;
    font-size: 1.4rem;
    cursor: pointer;
    margin-left: 3px;
  }
}

.messagebar-attachments {
  .upload-progress {
    margin: 20px 20px;
    text-align: left;
  }
}

.message .message-bubble-img-wrap {
  text-align: left;
}

.md .messagebar-attachments {
  padding: 5px;
}

.messagebar-attachments-visible {
  .mentions-input-box .mentions {
    padding-top: 175px;
  }
  .mentions-input-box .mentions-autocomplete-list {
    padding-top: 175px;
  }
}

.mentions-input-box .mentions-autocomplete-list ul {
  max-height: 200px;
  overflow-y: auto;
}

.mentions-input-box .mentions-autocomplete-list li > img,
.mentions-input-box .mentions-autocomplete-list li > div.icon {
  width: 24px;
  height: 24px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  -moz-background-origin: 3px;
  border-radius: 50%;
}

.mentions-input-box .mentions-autocomplete-list li > img,
.mentions-input-box .mentions-autocomplete-list li > div.icon {
  width: 25px;
  height: 25px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  -moz-background-origin: 3px;
  border-radius: 50%;
  background-color: #ccc;
}

.mentions-input-box .mentions-autocomplete-list li:last-child {
  border-radius: initial;
  border-bottom: initial;
}

.mentions-input-box .mentions-autocomplete-list li[belongto=""] {
  background-color: rgb(0 0 0 / 6%);
}

.theme-dark {
  .mentions-input-box .mentions-autocomplete-list li {
    color: #eee !important;
  }
  .mentions-input-box .mentions-autocomplete-list li b {
    color: var(--ion-color-light-tint);
  }
  .mentions-input-box .mentions-autocomplete-list li:hover,
  .mentions-input-box .mentions-autocomplete-list li.active {
    background-color: var(--f7-menu-dropdown-divider-color);
  }
  .mentions-input-box .mentions > div > strong {
    background: var(--f7-messagebar-attachments-border-color);
  }
  .mentions-input-box .mentions-autocomplete-list li[belongto=""] {
    background-color: var(--f7-toast-bg-color);
  }
  .message.message-received .message-content a.mention {
    background: rgb(255 255 255 / 18%);
  }
}

@supports (
  (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))
) {
  .mentions-input-box .mentions-autocomplete-list ul {
    background-color: rgba(var(--f7-dialog-bg-color-rgb), 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
}

/** End mentions input*/

/** Tree style*/

// .treeview-item-selectable>.treeview-item-root,
// .treeview-item-selectable.treeview-item-root {
//     border-bottom: 1px solid var(--f7-chip-outline-border-color);
//     font-size: var(--f7-list-item-subtitle-font-size);
//     font-weight: var(--f7-list-item-subtitle-font-weight);
//     color: var(--f7-list-item-subtitle-text-color);
//     line-height: var(--f7-list-item-subtitle-line-height);
// }
// .treeview-item-selectable>.treeview-item-root:nth-last-child,
// .treeview-item-selectable.treeview-item-root:nth-last-child {
//     border-bottom: none;
// }
// .treeview-item-selectable>.treeview-item-root:hover,
// .treeview-item-selectable.treeview-item-root:hover {
//     background-color: #00000012;
// }

/** End Tree style */

/** google-maps */

#google-maps {
  .gm-style {
    img[src*="store/files"] {
      border-radius: 50%;
    }
  }
}

/** end google-maps */

/** Price Report */
.cart {
  ul {
    background: none;
    > li {
      margin-bottom: 5px;
    }
  }
  ul:before,
  ul:after {
    display: none;
  }
  .product-item {
    display: block;
    font-size: 12px;
    padding-top: 0;
    background: var(--f7-list-bg-color);

    position: relative;
    .remove-btn {
      position: absolute;
      line-height: 30px;
      cursor: pointer;
      font-size: 14px;
      color: var(--ion-color-step-450);
      right: 8px;
      top: 0px;
    }
    .categories {
      float: left;
      width: 100%;
      font-weight: bold;
      // min-height: 30px;
      // line-height: 30px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .image {
      width: 30%;
      float: left;
      margin-bottom: 5px;
      background-position: center;
      background-size: 70%;
      background-repeat: no-repeat;
      background-image: url("/assets/images/no-image-available.png");
      border-radius: 5px;
      overflow: hidden;
      .image-contain {
        height: 86px;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;
        // border: 1px var(--f7-segmented-strong-bg-color) solid;
        overflow: hidden;
      }
    }
    .detail {
      padding-left: 5px;
      width: 70%;
      float: left;
      padding-right: 15px;
      margin-bottom: 5px;
      // .name {
      // }
      .price {
        .sales {
          color: var(--f7-input-error-text-color);
          font-weight: bold;
        }
        .origin {
          font-size: 10px;
          text-decoration: line-through;
          color: var(--f7-color-gray-tint);
        }
      }
      .unit {
        float: right;
        font-weight: bold;
      }
      .vat {
        float: left;
        font-weight: bold;
        line-height: 26px;
      }
      .quantity {
        display: flex;
        float: left;
        background: #ccc;
        line-height: 40px;
        font-size: 24px;
        font-weight: bold;
        .control {
          text-align: center;
          width: 50px;
          background: var(--ion-color-step-700);
          text-align: center;
          cursor: pointer;
        }
        input {
          background: #ccc;
          border: none;
          text-align: center;
          width: 100px;
          padding: 0;
          color: #ff4961;
        }
      }
      .voucher-price {
        display: flex;
        float: left;
        background: #ccc;
        line-height: 20px;
        .control {
          text-align: center;
          width: 30px;
          background: var(--ion-color-step-700);
          text-align: center;
          cursor: pointer;
        }
        input {
          background: #ccc;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0;
          color: #ff4961;
        }
      }
      .to-money {
        float: right;
        color: var(--f7-input-error-text-color);
        font-weight: bold;
        line-height: 26px;
      }
    }
    .promotion {
      width: 100%;
      float: left;
      height: 30px;
      line-height: 30px;
      border-top: 1px solid #adadad24;
      .chip {
        height: 18px;
        line-height: 18px;
        float: right;
        margin-right: 25px;
        margin-top: 5px;
      }
    }
  }
}
.clear-both {
  clear: both;
}
.data-form  {
  .item-title {
    font-weight: normal;
  }
}

.validate-required {
  color: var(--f7-color-orange) !important;
}

.thumbnail-list {
  .list-item {
    border: 1px solid var(--f7-list-item-border-color);
    padding: 0; overflow: hidden; margin-top: 10px; margin-bottom: 10px;
    .detail-header {
      display: flex; align-items: baseline; 
      // border-bottom: 1px solid var(--f7-list-item-border-color); 
      padding: 0.5rem;
      position: relative;

      height: 44px;
      display: flex;
      align-items: center;

      .header-label {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 20px;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: var(--f7-list-item-border-color);
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
      }
    }
    .detail-footer {
      // border-top: 1px solid var(--f7-list-item-border-color); 
      padding: 0.3rem;
      position: relative;
      font-size: 0.7rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 25px;
      &:before {
        content: '';
        position: absolute;
        background-color: var(--f7-list-item-border-color);
        display: block;
        z-index: 15;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
      }
    }
    .header-label {
      flex: 1; font-weight: bold
    }
    .detail-remove {
      cursor: pointer;
      i.icon {
        font-size: 1.5rem
      }
    }
    .detail-body {
      display: flex; 
      padding: 0.5rem; 
      padding-right: 0; 
      padding-bottom: 0;
      padding-top: 0;

      .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label,
      .smart-select .item-title {
        width: 50%;

        font-weight: bold;
        font-size: 0.8rem;

        padding-top: initial;
        align-self: center;
      }
    }
    .image-wrap {
      padding-top: 0.5rem;
      padding-top: 0.5rem;
      .image {
        background-color: var(--f7-contacts-list-title-bg-color);
        max-width: 150px; 
        width: 30vw; 
        height: 112px; 
        border-radius: 0.5rem; 
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        cursor: pointer;
      } 
    }
    .detail-content-wrap {
      flex: 1; margin-left: 0.5rem;
      overflow: hidden;
      .list {
        margin: 0;
      }

      .item-content.item-input {
        padding-left: 0; 
        min-height: 2rem;
        .item-input-wrap {
          display: flex; 
          flex-direction: row
        }
        .inputmask {
          height: 2rem; 
          text-align: right;
        }
        .currency-symbol {
          line-height: 2rem; 
          padding-left: 2px;
        }
      }


      .item-content {
        padding-left: 0; 
        min-height: 2rem;
        .stepper {
          input {
            width: 100%;
          }
        }
      }
      .item-inner {
        min-height: 2rem;
        padding-top: 0; 
        padding-bottom: 0; 
        min-height: initial;
      }

      .smart-select {
        padding-left: 0;
        .item-content {
          padding-left: 0; 
          min-height: 2rem;

          .item-inner {
            display: flex; 
            min-height: 2rem; 
            padding-top: 0; 
            padding-bottom: 0;
          }
        }

      }
    }

  }

}


.list-complex-info {
  ul {
    li {
      .item-title {
        height: 36px;
        display: flex;
        align-items: center;
        .item-title-text {
          font-size: 0.9rem;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 18px;
        }
      }
      .item-after {
        display: flex;
        align-items: center;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.popup.autocomplete-popup {
  z-index: 14500;
}
.popup.photo-browser-popup {
  z-index: 15500;
}

/** Custom scroll bar */
// ::-webkit-scrollbar {
//   width: 0.3125rem;
//   height: 0.3125rem;
// }
// ::-webkit-scrollbar-thumb {
//   background: #ff9800;
//   cursor: pointer;
//   border-radius: 0.15625rem;
// }
// ::-webkit-scrollbar-track {
//   background: var(--f7-scrollbar-background);
// }
.thin-scrollbar::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #ff9800;
  cursor: pointer;
  border-radius: 0.15625rem;
}
.thin-scrollbar::-webkit-scrollbar-track {
  background: var(--f7-scrollbar-background);
}
/** End Custom scroll bar */

/** End Price Report */


/* Keyoard */
.page.skip-keyboard-resize {
  .page-content {
    padding-bottom: 300px;
  }
}
/* End keyoard */

/* Embed barcode scanner*/
body {
  background: transparent;
}


@keyframes showEmbedBarcodeScanner {
  from {margin-top: 0px; height: 100%;}
  to {margin-top: 200px; height: calc(100% - 200px);}
}

body.embed-barcode-scanner {
  margin-top: 200px;
  height: calc(100% - 200px);
  background: transparent;
  animation-name: showEmbedBarcodeScanner;
  animation-duration: 300ms;
}
.page-content.embed-barcode-scanner {
  margin-top: 200px;
  height: calc(100% - 200px);
  background: transparent;
}

/*End embed barcode scanner*/


.page .profile-info {
  margin-top: 20px;
}

.page .profile-logo {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
}
.page .profile-info .info {
  flex: 1;
  padding-left: 1rem;
  padding-top: 0.5rem;
  font-size: 0.7rem;
}

.list ul li.autocomplete .item-after {
  white-space: normal;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* display: -webkit-box; */
    /* -webkit-box-orient: vertical; */
    /* -webkit-line-clamp: 2; */
    line-height: 1.2rem;
    width: 100%;
    height: initial;
    padding-left: 0;
}